import React from 'react';
import Daisy from '../assets/Daisy.png';


const Loader = () => {
    return(
        <div style={{display:'inline-grid',
        justifyContent:'center',
        alignItems:'center',height: '100%', maxWidth:"200px"}} className="loader">
            <img style={{animation: `spin 3s linear infinite`,display:'flex',
   justifyContent:'center',
   alignItems:'center', top: '50% !important,padding: 12px 40px',
   borderRadius: '15px 15px',
   marginLeft: '40%',
   marginTop:'20%',
   alignItems: 'center',
   position: 'relative',
   justifyContent: 'center'}} src={Daisy} alt="Daze" />
   <div style={{padding:'40px'}}> </div>
   <div id="row" style={{width:"100%"}}>
    <h1 style={{textAlign:"center",color:"#5ea8fd",fontSize:"3em",fontFamily:"arial-black"}}>Woof Woof!</h1>
</div>
        </div>
    )
}

export default Loader;