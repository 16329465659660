import React from 'react';
import Giphy from "./components/Giphy";

import "./App.css"

const App = () => {
    return(
<div>
<Giphy />
</div>   
);
}

export default App;