import axios from 'axios';
import React, {useEffect, useState} from 'react';
import Loader from './Loader';



const Giphy = () => {
 const [data, setData] = useState([]);
 const [isLoading, setIsLoading] = useState(false);
 const [counter, setCounter] = useState(1);
 const [puppySearch, setPuppySearch] = useState(0);

  
 
useEffect(()=> {
const fetchData = async () => {
    setIsLoading(true);
   const res = await axios("https://api.giphy.com/v1/gifs/search?&limit=1&q=puppies",{
params: {
    api_key: "KRNijEbtFBVbv7SrrI43DNxZb22njqfk",
}

});

setData(res.data.data);
setTimeout(() => {
    setIsLoading(false);
  }, 1000);
}

fetchData()
}, []);

const renderGifs = () => {
    if(isLoading){
        return <div className="row">
            <Loader />
        </div>
    }
    return data.map(el => {
        if(counter <= 1){

        }else{
            return (
                <div key={el.id} className="gif">
                <img src={el.images.fixed_height.url} />
                 </div>  
            )
        }
    })
}

const handleSubmit = async event => {
    event.preventDefault();
    const index = Math.floor(Math.random() * 10)
    const pupSearchParams = ['puppies', 'pups', 'dogs', 'real puppy', 'puppers', 'nice puppies', 'cuddly puppies', 'cute dog', 'cute doggos', 'cute puppy', 'cute puppies']
    setCounter(count => count + 3);
    setIsLoading(true);
    const res = await axios("https://api.giphy.com/v1/gifs/search?",{
 params: {
     api_key: "KRNijEbtFBVbv7SrrI43DNxZb22njqfk",
     limit: counter,
     q: pupSearchParams[index],
 }
 
 });
 
 console.log(res);
 console.log(index);
 setData(res.data.data);
 setTimeout(() => {
     setIsLoading(false);
   }, 1000);

}

return (
    <div>
<div style={{paddingTop:"100px"}} />
<div id="row" style={{}}>
    <h1 style={{textAlign:"center",color:"#5ea8fd",fontSize:"4em",fontFamily:"arial-black"}}>THE PUPPY BUTTON</h1>
</div>
<div style={{overflowY: "scroll"}} />
<div className="container gifs" style={{display: "inline-flex"}}>{renderGifs()} </div>
<div style={{padding:"100px",display:"inline-grid"}} />
<button 

id="thePuppyButton"
onClick={
    handleSubmit
}
type="submit"
className="btn btn-primary mx-2"
><i class='fas fa-paw'></i> The Puppy Button </button>
<div style={{padding:"100px",display:"inline-grid"}} />
<div style={{padding:"100px",display:"inline-grid"}} />
    </div>
    
    );




}

export default Giphy;